import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { ZZZDiskSet } from '../../../modules/zzz/common/components/zzz-disk-set';

const ZZZAgentsDiskDrivesAbout: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Drive Disks</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_disk.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Drive Disks</h1>
          <h2>
            Drive Disks from Zenless Zone Zero explained - how the gear works in
            the game.
          </h2>
          <p>
            Last updated: <strong>04/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Drive Disks" />
        <StaticImage src="../../../images/zzz/guides/drive.webp" alt="Guide" />
        <p>
          Drive Disks are a type of equipment in this game and serve the
          function of armor and accessories in a traditional RPG game -{' '}
          <strong>
            they're the equivalent of Relics from Honkai: Star Rail
          </strong>
          .
        </p>
        <p>
          Here's a summary of information about the Drive Disks that we know so
          far:
        </p>
        <ul>
          <li>
            Equipping Drive Disks to your Agents will provide them with a
            variety of stat increases from HP to ATK to CRIT,
          </li>
          <li>
            Drive Disks will provide your Agents with the main bulk of their
            stat increases,
          </li>
          <li>
            Each Agent can equip up to <strong>six Drive Disks</strong>,
          </li>
          <ul>
            <li>
              Numbered in the game with the Roman numerals: I, II, III, IV, V,
              and VI (you can see it also on the image above),
            </li>
          </ul>
          <li>
            Each Drive Disk has one <strong>base stat</strong> and up to{' '}
            <strong>4 random sub-stats</strong>,
          </li>
          <li>
            Equipping a required number (either 2 or 4) of Drive Disks belonging
            to the same set, will unlock <strong>Set Effects</strong>.
          </li>
        </ul>
        <SectionHeader title="Stats" />
        <StaticImage
          src="../../../images/zzz/guides/drive_2.webp"
          alt="Guide"
        />
        <h5>Main Stats</h5>
        <p>
          Each Drive Disk has one main stat and up to 4 sub-stats. Depending on
          the Drive Disk slot it fits in, the possible main stats are:
        </p>
        <ul>
          <li>
            <strong>I</strong> - Flat HP
          </li>
          <li>
            <strong>II</strong> - Flat ATK
          </li>
          <li>
            <strong>III</strong> - Flat DEF
          </li>
          <li>
            <strong>IV</strong> - HP%, ATK%, DEF%, CRIT Rate%, CRIT DMG%,
            Anomaly Proficiency
          </li>
          <li>
            <strong>V</strong> - HP%, ATK%, DEF%, PEN Ratio%, Element DMG%*
          </li>
          <ul>
            <li>
              *Element DMG is random and can roll any of the 5 elements
              available in the game,
            </li>
          </ul>
          <li>
            <strong>VI</strong> - HP%, ATK%, DEF%, Anomaly Mastery%, Energy
            Regen%, Impact%
          </li>
        </ul>
        <h5>Sub Stats</h5>
        <p>
          Drive Disks sub-stats are randomly generated. The list of possible
          substats are:
        </p>
        <ul>
          <li>Flat HP</li>
          <li>Flat ATK</li>
          <li>Flat DEF</li>
          <li>HP%</li>
          <li>ATK%</li>
          <li>DEF%</li>
          <li>Crit Rate%</li>
          <li>Crit DMG%</li>
          <li>PEN (flat)</li>
          <li>Anomaly Proficiency</li>
        </ul>
        <h5>Upgrading Drive Disks</h5>
        <p>
          Drive Disks can also be leveled up to increase the main stat bonus,
          but the sub-stats are generated when you obtain the item (or when you
          enhance it) and <strong>they can't be changed</strong>. The maximum
          level of a Drive Disks is determined by its rarity which ranges from B
          to S rank. The higher the rarity of a Drive Disk, the higher the stats
          it will have at its base level and when upgraded.
        </p>
        <p>
          Also, if the Drive Disk has fewer than 4 sub-stats, it gains a new
          random sub-stat. If the Drive Disk already has 4 sub-stats, a random
          sub-stat is increased by a random amount.
        </p>
        <p>
          The table below shows the Drive Disk information in an easier form to
          read:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Rarity</th>
              <th>Color</th>
              <th>Max level</th>
              <th>Base number of sub-stats</th>
              <th>Number of unlocks</th>
              <th>Number of upgrades</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="hsr-rar rar-3">B</span>
              </td>
              <td>Blue</td>
              <td>+9</td>
              <td>1-2</td>
              <td>2-3</td>
              <td>0-1</td>
            </tr>
            <tr>
              <td>
                <span className="hsr-rar rar-4">A</span>
              </td>
              <td>Purple</td>
              <td>+12</td>
              <td>2-3</td>
              <td>1-2</td>
              <td>2-3</td>
            </tr>
            <tr>
              <td>
                <span className="hsr-rar rar-5">S</span>
              </td>
              <td>Gold</td>
              <td>+15</td>
              <td>3-4</td>
              <td>0-1</td>
              <td>4-5</td>
            </tr>
          </tbody>
        </Table>
        <p>
          If you want to learn more about the Main and Sub Stats, check this
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks Stats"
            link="/zenless/guides/disk-drives-stats"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disk2.png"
                alt="Drive Disks Stats"
              />
            }
          />
        </Row>
        <SectionHeader title="Set effects" />
        <p>
          Drive Disk also come in sets and equipping multiple Drive Disk from
          the same set will provide additional bonuses for the Agent. Compared
          to Honkai: Star Rail, the sets aren't split into two groups and they
          all have 2-piece and 4-piece bonus.
        </p>
        <p>Below you can find a few examples sets:</p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <ZZZDiskSet name="Inferno Metal" />
          </Col>
          <Col>
            <ZZZDiskSet name="Thunder Metal" />
          </Col>
        </Row>
        <p>If you want to see all available Disk Sets, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks Sets"
            link="/zenless/disk-drives"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disksets.png"
                alt="Drive Disks Sets"
              />
            }
          />
        </Row>
        <SectionHeader title="How to obtain" />
        <StaticImage
          src="../../../images/zzz/guides/drive_3.webp"
          alt="Guide"
        />
        <p>
          You can obtain the Drive Disks in two ways. The first method is
          basically 'gachaing' for them via Music Store:
        </p>
        <ul>
          <li>
            You can use <strong>Plating Agents</strong> at the music store -
            Bardic Needle - to obtain new random Drive Disks,
          </li>
          <ul>
            <li>
              You can obtain Plating Agents from Commissions that cost stamina,
            </li>
          </ul>
          <li>
            Once your Account Level is high enough, the music store will level
            up, unlocking new features (including new Disk Sets you can obtain),
          </li>
          <li>
            <strong>Tuning Orientation</strong> allows you to pick a set and
            increase its drop rate (check below images).
          </li>
        </ul>
        <p>
          The second way is very similar to Caverns of Corrosion from Honkai:
          Star Rail and you can farm them in <strong>Driver Validation.</strong>
        </p>
        <StaticImage
          src="../../../images/zzz/guides/validation.webp"
          alt="Guide"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZAgentsDiskDrivesAbout;

export const Head: React.FC = () => (
  <Seo
    title="Drive Disks | Zenless Zone Zero | Prydwen Institute"
    description="Drive Disks from Zenless Zone Zero explained - how the gear works in the game."
    game="zzz"
  />
);
